import Checkbox from 'atoms/Checkbox';
import Button from '../atoms/Button';
import DialogBox from '../molecules/DialogBox';
import TermsAndConditionContent from './TermsAndConditionContent';

const TermsAndCondition = ({
    show, onConfirm, buttonLoading, isSelected, handleChange, disabled, onClose
}) => {
    if (!show) return null;
    return (
        <DialogBox
            title=" "
            subTitle=''
            width='w-full max-w-[912px]'
            isClose={false}
            close={onClose}
            bodySpacing="mb-6"
            titleStyle='text-center mb-1'>
            <div className="text-gray-200 max-h-[50vh] overflow-auto blue-scrollbar mb-3 pr-3">
                <TermsAndConditionContent secondary={false} />
            </div>
            <Checkbox
                isSelected={isSelected}
                handleChange={handleChange}
                checkBoxLabel="I have read and agree to the term listed above"
                labelClassName="!text-gray-200"
            />
            <div className='flex justify-center mt-6'>
                <Button label="Accept the terms" type='primary' onClick={onConfirm} loading={buttonLoading} disabled={disabled} />
            </div>
        </DialogBox >
    );
};

export default TermsAndCondition;
